import { createAsyncThunk } from "@reduxjs/toolkit";
import { handleResponseError } from "../../helpers/handleResponseError";
import { defaults } from "../../constants/config";
import { IConfig } from "../../model/config/IConfig";
import { IFileConfig } from "../../model/config/IFileConfig";
import { initializeGoogleAnalytics } from "../../utils/Analytics";

const reloadTried = "RELOAD_TRIED";

export const fetchConfig = createAsyncThunk<IConfig>("config/fetch", async (arg, api) => {
    let response: Response | undefined;
    try {
        response = await fetch(process.env.PUBLIC_URL + "/config.json?" + new Date().toISOString(), {
            headers: {
                Accept: "application/json",
            },
            signal: api.signal,
        });
        sessionStorage.removeItem(reloadTried);
    } catch (error) {
        console.error(error);
        if (!sessionStorage.getItem(reloadTried)) {
            sessionStorage.setItem(reloadTried, "1");
            document.body.innerHTML = "";
            window.location.reload();
        } else {
            sessionStorage.removeItem(reloadTried);
            throw error;
        }
    }

    let partialConfig: Partial<IFileConfig> = {};
    if (response && response.status !== 404) {
        await handleResponseError(response);

        partialConfig = await response.json();

        const gaId = partialConfig.googleAnalyticsId;
        if (gaId) initializeGoogleAnalytics(gaId);
    }

    const config: IFileConfig = {
        endpoint: partialConfig.endpoint ?? defaults.endpoint,
        syncEndpointOriginWithClient:
            partialConfig.syncEndpointOriginWithClient ?? defaults.syncEndpointOriginWithClient,
        debug: partialConfig.debug ?? defaults.debug,
        autoLogoutMinutes: partialConfig.autoLogoutMinutes ?? defaults.autoLogoutMinutes,
        infoText: partialConfig.infoText ?? defaults.infoText,
        uploadSize: partialConfig.uploadSize ?? defaults.uploadSize,
        uploadQuality: partialConfig.uploadQuality ?? defaults.uploadQuality,
    };

    // sync endpoint protocol & port with client
    if (config.syncEndpointOriginWithClient) {
        const endpoint = new URL(config.endpoint);
        const location = window.location;

        endpoint.protocol = location.protocol;
        endpoint.hostname = location.hostname;
        endpoint.port = location.port;

        config.endpoint = endpoint.toString();
    }

    return {
        endpoint: config.endpoint,
        debug: config.debug,
        autoLogoutMinutes: config.autoLogoutMinutes,
        infoText: config.infoText,
        uploadSize: config.uploadSize,
        uploadQuality: config.uploadQuality,
    } as IConfig;
});
